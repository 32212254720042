import { ActionIcon, Box, Flex } from '@mantine/core';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useMemo } from 'react'
import ExportExcel from '../../ExportExcel';
import { BsPrinter } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { GoInfo } from 'react-icons/go';

function TabAbsenKeluar ( {
    userDetail,
    karyawanid,
    absensiKeluar,
    formattedTotalEarlyDuration,
    formattedTotalOverDuration,
    handleAbsensiDetailTabKeluar
} )
{

    const navigate = useNavigate();

    const printAbsensi = () =>
    {
        navigate( "/absen-keluar/" + karyawanid );
    }

    const columns2 = useMemo(
        () => [
            {
                header: 'Detail',
                accessorFn: row => (
                    <div >
                        <GoInfo
                            size={ 20 }
                            onClick={ () => handleAbsensiDetailTabKeluar( row.checkin ) }
                            style={ { cursor: 'pointer' } }
                        />

                    </div>
                ),
                mantineTableHeadCellProps: {
                    align: 'left',
                },
                mantineTableBodyCellProps: {
                    align: 'left',
                },
                size: 100,
            },
            {
                header: 'Tanggal',
                id: 'tanggal',
                accessorFn: ( row ) => new Date( row.checkout_time ),
                filterVariant: 'date-range',
                Cell: ( { cell } ) =>
                {
                    const date = cell.getValue();
                    const day = String( date.getDate() ).padStart( 2, '0' );
                    const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Jam',
                accessorFn: ( row ) => (
                    row.checkout_time.split( 'T' )[ 1 ].split( '.' )[ 0 ]
                ),
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Pulang Awal',
                accessorKey: 'early_duration',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,

            },
            {
                header: 'Lembur',
                accessorKey: 'overtime_duration',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,

            },
            {
                header: 'Lokasi',
                accessorKey: 'checkout_place',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,

            },

        ],
        [],
    );

    const handleExportRowsExcelData2 = () =>
    {
        const excelData = absensiKeluar.map( ( row ) => ( {
            'Absen Pulang': row.checkout_time,
            'Pulang Awal': row.early_duration,
            'Lembur': row.overtime_duration,
            'Total Pulang Awal Bulan Ini': formattedTotalEarlyDuration,
            'Total Lembur Bulan Ini': formattedTotalOverDuration,
        } ) );
        return excelData;
    };

    const table2 = useMantineReactTable( {
        columns: columns2,
        enableDensityToggle: false,
        initialState: {
            density: 'xs',
            sorting: [
                {
                    id: 'tanggal', //sort by age by default on page load
                    desc: true,
                },
            ],
        },
        data: absensiKeluar,
        enableRowNumbers: true,
        rowNumberMode: 'static',
        enableGlobalFilter: false,
        enableColumnResizing: false,
        isMultiSortEvent: () => true,
        mantineTableProps: {
            striped: true,
        },
        renderTopToolbarCustomActions: ( { table } ) => (
            <Box
                sx={ {
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                } }
            >
            </Box>
        ),
        renderToolbarInternalActions: ( { table } ) => (
            <Flex gap="xs" align="center">
                {/* add custom button to print table  */ }
                <ActionIcon
                    onClick={ printAbsensi }
                >
                    <BsPrinter
                        style={ { backgroundColor: 'transparent', color: '#222' } }
                        size={ 40 }
                    />
                </ActionIcon>
                <ActionIcon>
                    <ExportExcel
                        excelData={ handleExportRowsExcelData2( table.getRowModel().rows ) }
                        fileName={ `Data Absensi Pulang ${userDetail?.first_name} ${userDetail?.last_name}` }
                    />
                </ActionIcon>
            </Flex>
        ),
    } );

    return (
        <div>
            <MantineReactTable
                table={ table2 }
            />
        </div>
    )
}

export default TabAbsenKeluar

import React, { useContext, useState } from 'react'
import AuthContext from '../../../auth/Context/AuthContext';
import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../../../adapters/API/axios';

function ModalTambahDivisi ( {
    showAddDivisi,
    setShowAddDivisi,
    fetchListDivisi
} )
{
    const { tokens } = useContext( AuthContext );
    const tokenUser = tokens?.token;

    const [ divisiName, setDivisiName ] = useState( '' );


    const handleCloseAddDivisi = () =>
    {
        setShowAddDivisi( false );
        setDivisiName( '' );
    };

    const handleSubmit = async ( event ) =>
    {
        event.preventDefault()

        const data = {
            name: divisiName,
        }
        try {
            const response = await axios.post( `/api/groups/`, data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        Authorization: `Token ` + tokenUser,
                    },
                    httpsAgent: {
                        rejectUnauthorized: false,
                    }
                }

            );
            handleCloseAddDivisi();
            Swal.fire( {
                icon: 'success',
                title: 'Divisi berhasil ditambahkan',
                showConfirmButton: false,
                timer: 2000
            } )
            fetchListDivisi();
        } catch ( err ) {
            console.log( err )
            if ( !err?.response ) {
                Swal.fire( {
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Server tidak ada respon',
                } )
                handleCloseAddDivisi();
            } else if ( err.response?.status === 400 ) {
                Swal.fire( {
                    icon: 'error',
                    title: 'Oops...!',
                    text: `Divisi sudah ada`,
                } )

            }
        }
    };

    return (
        <Modal
            show={ showAddDivisi }
            onHide={ handleCloseAddDivisi }
            backdrop="static"
            keyboard={ false }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title style={ { fontFamily: 'Poppins-Medium' } }>
                    Tambah Divisi
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={ handleSubmit }>
                    <Form.Group className="mb-3">
                        <Form.Label style={ formStyles.label } htmlFor='divisiName'>Nama Divisi*</Form.Label>
                        <Form.Control
                            id='divisiName'
                            type="text"
                            onChange={ ( e ) => setDivisiName( e.target.value ) }
                            value={ divisiName }
                            required
                            style={ formStyles.input }
                        />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button
                            variant="btn"
                            type='submit'
                            style={ { backgroundColor: '#12B3ED', color: 'white', fontFamily: 'Poppins-Regular' } }>
                            Tambah
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalTambahDivisi


const formStyles = {
    label: {
        fontFamily: 'Poppins-Medium',
        color: '#222',
    },
    input: {
        color: '#222',
        fontFamily: 'Poppins-Regular',
        minHeight: '50px',
        borderColor: '#ced4da', // Initial border color
    },
};
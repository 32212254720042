import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

function ModalVisitComp ( {
    openModalVisit,
    setOpenModalVisit,
    visitPlace,
    setVisitPlace,
    handleSubmitVisitIn,
    handleCloseModal
} )
{


    return (
        <Modal
            show={ openModalVisit }
            onHide={ handleCloseModal }
            backdrop="static"
            keyboard={ false }
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title style={ { fontFamily: 'Poppins-Medium' } }>
                    Visit Masuk
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={ handleSubmitVisitIn } >
                    <Form.Group className="mb-3">
                        <Form.Label style={ formStyles.label } htmlFor='visitPlace'>Lokasi Visit*</Form.Label>
                        <Form.Control
                            id='visitPlace'
                            type="text"
                            value={ visitPlace }
                            onChange={ ( e ) => setVisitPlace( e.target.value ) }
                            required
                            style={ formStyles.input }
                        />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button
                            variant="btn"
                            type='submit'
                            style={ { backgroundColor: '#12B3ED', color: 'white', fontFamily: 'Poppins-Regular' } }>
                            Kirim
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalVisitComp


const formStyles = {
    label: {
        fontFamily: 'Poppins-Medium',
        color: '#222',
    },
    input: {
        color: '#222',
        fontFamily: 'Poppins-Regular',
        minHeight: '50px',
        borderColor: '#ced4da', // Initial border color
    },
};

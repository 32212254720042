import React, { useEffect, useState } from 'react'
import { Container, Spinner, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

function PrintReportAbsen ()
{
    const location = useLocation();
    const { dataTable } = location.state;
    const { group_name } = location.state;
    const { start_date } = location.state;
    const { end_date } = location.state;

    const [ done, setDone ] = useState( false );
    const navigate = useNavigate();


    useEffect( () =>
    {
        const timer = setTimeout( () =>
        {
            setDone( true );
        }, 2000 );

        return () => clearTimeout( timer );
    }, [] );

    useEffect( () =>
    {
        if ( done ) {
            window.print();
        }
    }, [ done ] );

    useEffect( () =>
    {
        const handleAfterPrint = () =>
        {
            navigate( -1 );
        };

        window.addEventListener( 'afterprint', handleAfterPrint );

        return () =>
        {
            window.removeEventListener( 'afterprint', handleAfterPrint );
        };
    }, [ navigate ] );

    return (
        <Container fluid style={ { minHeight: '100vh', overflowX: 'hidden' } }>
            <div className='text-start'>
                <h1 className='mt-2' style={ { fontFamily: 'Poppins-Regular' } }>Report Absensi { group_name } Dari Tanggal { start_date } Sampai Tanggal { end_date }</h1>
            </div>
            { !done ? (
                <Container className='text-center mb-3'>
                    <div className='my-5'>
                        <Spinner animation="border" size='lg' style={ customSpinnerStyle } />
                    </div>
                </Container>
            ) : (
                <>
                    <div className='mt-3'>
                        <Table responsive >
                            <thead style={ { fontFamily: 'Poppins-Regular' } }>
                                <tr>
                                    <th>#</th>
                                    <th>Username</th>
                                    <th>Jumlah Absen Masuk</th>
                                    <th>Jumlah Absen Pulang</th>
                                    <th>Total Keterlambatan</th>
                                    <th>Total Pulang Cepat</th>
                                    <th>Total Lembur</th>
                                </tr>
                            </thead>
                            <tbody style={ { fontFamily: 'Poppins-Light' } }>
                                {
                                    dataTable?.map( ( data, index ) =>
                                    {
                                        return (

                                            <tr key={ index }>
                                                <td>{ index + 1 }</td>
                                                <td>{ data?.user }</td>
                                                <td>{ data?.jumlah_absen_masuk }</td>
                                                <td>{ data?.jumlah_absen_pulang }</td>
                                                <td>{ data?.late_duration }</td>
                                                <td>{ data?.early_duration }</td>
                                                <td>{ data?.overtime_duration }</td>
                                            </tr>

                                        )
                                    } )
                                }
                            </tbody>
                        </Table>
                    </div>
                </>
            ) }
        </Container>
    )
}

export default PrintReportAbsen


const customSpinnerStyle = {
    color: '#000A2E',
};
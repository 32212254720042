import React, { useContext, useEffect, useMemo, useState } from 'react'
import AuthContext from '../../auth/Context/AuthContext';
import axios from '../../adapters/API/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import NavbarComponent from '../../component/Navbar';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { IoPersonAdd } from 'react-icons/io5';
import ModalTambahDivisi from '../../component/Modal/ModalTambahDivisi';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { GoPencil } from 'react-icons/go';
import { MdDelete, MdGroups } from 'react-icons/md';
import ModalEditDivisi from '../../component/Modal/ModalEditDivisi';

function Divisi ()
{
    const { tokens } = useContext( AuthContext );
    const [ listGrup, setListGroup ] = useState( [] );
    const tokenUser = tokens?.token;
    const navigate = useNavigate();

    const [ showAddDivisi, setShowAddDivisi ] = useState( false );
    const handleShowAddDivisi = () =>
    {
        setShowAddDivisi( true );
    }

    const [ showEditDivisi, setShowEditDivisi ] = useState( false );
    const [ idSelected, setIdSelected ] = useState()
    const handleShowAEditDivisi = ( row ) =>
    {
        setShowEditDivisi( true );
        setIdSelected( row )
    }

    useEffect( () =>
    {
        if ( tokenUser !== undefined ) fetchListDivisi()
    }, [ tokenUser ] );

    const fetchListDivisi = () =>
    {
        axios.get( `/api/groups/`,
            {
                headers:
                {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: `Token ` + tokenUser,
                },

            } )
            .then( res =>
            {

                setListGroup( res.data );
            } ).catch( err =>
            {

                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };

    const handleDelete = async ( rowId ) =>
    {
        const result = await Swal.fire( {
            title: 'Apakah anda yakin ingin menghapus divisi ini?',
            text: 'Kamu tidak dapat mengembalikan data divisi ini!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, hapus divisi ini!',
            cancelButtonText: 'Batalkan',
        } );

        if ( result.isConfirmed ) {
            try {

                const responseDelete = await axios.delete( `/api/groups/${rowId}`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        withCredentials: true,
                        Authorization: `Token ${tokens?.token}`,
                    },
                } );
                // console.log( responseDelete )
                Swal.fire( 'Terhapus!', 'Divisi berhasil dihapus.', 'success' );
                fetchListDivisi();
            } catch ( err ) {
                console.log( err );
                Swal.fire( 'Error', 'Terjadi kesalahan saat menghapus!', 'error' );
            }
        } else {

            Swal.fire( 'Dibatalkan', 'Divisi belum terhapus.', 'info' );
        }
    };

    const columns = useMemo(
        () => [
            {
                header: 'Nama Divisi',
                accessorKey: 'name',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                header: 'Ubah',
                accessorFn: row =>
                {
                    const isSuperuser = row.name === 'superuser';
                    return !isSuperuser ? (
                        <div>
                            <GoPencil
                                size={ 20 }
                                onClick={ () => handleShowAEditDivisi( row ) }
                                style={ { cursor: 'pointer' } }
                            />
                        </div>
                    ) : null;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                header: 'Hapus',
                accessorFn: row =>
                {
                    const isSuperuser = row.name === 'superuser';
                    return !isSuperuser ? (
                        <div >
                            <MdDelete
                                size={ 20 }
                                onClick={ () => handleDelete( row.id ) }
                                style={ { cursor: 'pointer' } }
                                color='red'
                            />
                        </div>
                    ) : null;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
            },
        ],
        [],
    );

    const table = useMantineReactTable( {
        columns,
        enableDensityToggle: false,
        initialState: {
            density: 'xs',
        },
        data: listGrup,
        enableRowNumbers: true,
        rowNumberMode: 'static',
        isMultiSortEvent: () => true,
        mantineTableProps: { striped: true },
    } );

    return (
        <>
            <NavbarComponent />
            <Container>
                <Row>
                    <Col xs={ 6 } md={ 10 }>
                        <h1 className='display-6 text-center' style={ { fontFamily: 'Poppins-Light' } }>Data Divisi</h1>
                    </Col>
                    <Col xs={ 6 } md={ 2 } className='my-auto text-end'>
                        <Button
                            onClick={ handleShowAddDivisi }
                            variant='btn'
                            style={ { minHeight: '50px', backgroundColor: '#12B3ED', color: 'white', fontFamily: 'Poppins-Regular' } }
                        >
                            Tambah <MdGroups size={ 25 } />
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container className='my-5'>
                <MantineReactTable
                    table={ table }
                />
            </Container>
            <ModalTambahDivisi
                showAddDivisi={ showAddDivisi }
                setShowAddDivisi={ setShowAddDivisi }
                fetchListDivisi={ fetchListDivisi }
            />
            <ModalEditDivisi
                showEditDivisi={ showEditDivisi }
                setShowEditDivisi={ setShowEditDivisi }
                fetchListDivisi={ fetchListDivisi }
                idSelected={ idSelected }
            />
        </>
    )
}

export default Divisi

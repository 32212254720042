import React, { useContext, useState } from 'react'
import AuthContext from '../../../auth/Context/AuthContext';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import Swal from 'sweetalert2';
import axios from '../../../adapters/API/axios';

function ModalEditDivisi ( {
    showEditDivisi,
    setShowEditDivisi,
    fetchListDivisi,
    idSelected
} )
{
    const { tokens } = useContext( AuthContext );
    const tokenUser = tokens?.token;


    const handleCloseEditDivisi = () =>
    {
        setShowEditDivisi( false );
    };

    const defaultValue = {
        name: idSelected?.name || '',
    }

    const handleEditDivisi = async ( values ) =>
    {


        const data = {
            name: values.name,
        }
        // console.log( data )
        try {
            const response = await axios.patch( `/api/groups/${idSelected.id}`, data,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        Authorization: `Token ` + tokenUser,
                    },
                    httpsAgent: {
                        rejectUnauthorized: false,
                    }
                }

            );
            handleCloseEditDivisi();
            Swal.fire( {
                icon: 'success',
                title: 'Divisi berhasil diubah',
                showConfirmButton: false,
                timer: 2000
            } )
            fetchListDivisi();
        } catch ( err ) {
            console.log( err )
            if ( !err?.response ) {
                Swal.fire( {
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Server tidak ada respon',
                } )
                handleCloseEditDivisi();
            } else if ( err.response?.status === 400 ) {
                Swal.fire( {
                    icon: 'error',
                    title: 'Oops...!',
                    text: `Divisi sudah ada`,
                } )
            }
        }
    };

    return (
        <Modal
            show={ showEditDivisi }
            onHide={ handleCloseEditDivisi }
            backdrop="static"
            keyboard={ false }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title style={ { fontFamily: 'Poppins-Medium' } }>
                    Ubah Divisi
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={ defaultValue }
                    enableReinitialize={ true }
                    onSubmit={ handleEditDivisi }
                >
                    { ( {
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        setFieldValue
                    } ) => (
                        <Form onSubmit={ handleSubmit }>
                            <Form.Group className="mb-3">
                                <Form.Label style={ formStyles.label } htmlFor='name'>Nama Divisi*</Form.Label>
                                <Form.Control
                                    name='name'
                                    type="text"
                                    value={ values.name }
                                    onChange={ handleChange }
                                    required
                                    style={ formStyles.input }
                                />
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button
                                    variant="btn"
                                    type='submit'
                                    style={ { backgroundColor: '#12B3ED', color: 'white', fontFamily: 'Poppins-Regular' } }>
                                    Ubah
                                </Button>
                            </div>
                        </Form>
                    ) }
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEditDivisi


const formStyles = {
    label: {
        fontFamily: 'Poppins-Medium',
        color: '#222',
    },
    input: {
        color: '#222',
        fontFamily: 'Poppins-Regular',
        minHeight: '50px',
        borderColor: '#ced4da', // Initial border color
    },
};
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import './index.css'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRouteComp from "./auth/PrivateRoute";
import Home from "./pages/Home";
import DataKaryawan from "./pages/DataKaryawan";
import DetailKaryawan from "./pages/DetailKaryawan";
import DetailAbsensi from "./pages/DetailAbsensi";
import AbsensiPage from "./pages/AbsensiPage";
import PrintPageAbsenMasuk from "./pages/PrintPage/PrintPageAbsenMasuk";
import PrintPageAbsenKeluar from "./pages/PrintPage/PrintPageAbsenKeluar";
import NotFoundPages from "./pages/NotFoundPages";
import Report from "./pages/Report";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import VisitPage from "./pages/DataVisit";
import PrintPageVisitMasuk from "./pages/PrintPage/PrintPageVisitMasuk";
import PrintPageVisitKeluar from "./pages/PrintPage/PrintPageVisitKeluar";
import DetailVisit from "./pages/DetailVisit";
import Divisi from "./pages/Divisi";
import PrintReportAbsen from "./pages/PrintPage/PrintReportAbsen";
import DetailAbsenKaryawan from "./pages/DetailAbsenKaryawan";
import ReportVisit from "./pages/ReportVisit";
import DetailAbsenVisitKaryawan from "./pages/DetailAbsenVisitKaryawan";
import PrintReportDetailAbsenKaryawanInti from "./pages/PrintPage/PrintReportDetailAbsenKaryawan";
import PrintReportDetailVisitKaryawanInti from "./pages/PrintPage/PrintReportDetailVisitKaryawan";
import PrintReportVisit from "./pages/PrintPage/PrintReportVisit";



function App ()
{

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Login /> } />
        <Route path="/home"
          element={
            <PrivateRouteComp >
              <Home />
            </PrivateRouteComp>
          }
        />
        <Route path="/data-absensi"
          element={
            <PrivateRouteComp >
              <AbsensiPage />
            </PrivateRouteComp>
          }
        />
        <Route path="/data-visit"
          element={
            <PrivateRouteComp >
              <VisitPage />
            </PrivateRouteComp>
          }
        />
        <Route path="/data-karyawan"
          element={
            <PrivateRouteComp >
              <DataKaryawan />
            </PrivateRouteComp>
          }
        />
        <Route path="/divisi"
          element={
            <PrivateRouteComp >
              <Divisi />
            </PrivateRouteComp>
          }
        />
        <Route path="/report"
          element={
            <PrivateRouteComp >
              <Report />
            </PrivateRouteComp>
          }
        />
        <Route path="/report-visit"
          element={
            <PrivateRouteComp >
              <ReportVisit />
            </PrivateRouteComp>
          }
        />
        <Route path="/report-visit-detail-karyawan/:karyawanid"
          element={
            <PrivateRouteComp >
              <DetailAbsenVisitKaryawan />
            </PrivateRouteComp>
          }
        />
        <Route path="/detail-karyawan/:karyawanid"
          element={
            <PrivateRouteComp >
              <DetailKaryawan />
            </PrivateRouteComp>
          }
        />
        <Route path="/detail-absen-karyawan/:karyawanid"
          element={
            <PrivateRouteComp >
              <DetailAbsenKaryawan />
            </PrivateRouteComp>
          }
        />
        <Route path="/detail-absensi/:absenid"
          element={
            <PrivateRouteComp >
              <DetailAbsensi />
            </PrivateRouteComp>
          }
        />
        <Route path="/detail-visit/:absenid"
          element={
            <PrivateRouteComp >
              <DetailVisit />
            </PrivateRouteComp>
          }
        />
        <Route path="/absen-masuk/:karyawanid"
          element={
            <PrivateRouteComp >
              <PrintPageAbsenMasuk />
            </PrivateRouteComp>
          }
        />
        <Route path="/absen-keluar/:karyawanid"
          element={
            <PrivateRouteComp >
              <PrintPageAbsenKeluar />
            </PrivateRouteComp>
          }
        />
        <Route path="/visit-masuk/:karyawanid"
          element={
            <PrivateRouteComp >
              <PrintPageVisitMasuk />
            </PrivateRouteComp>
          }
        />
        <Route path="/visit-keluar/:karyawanid"
          element={
            <PrivateRouteComp >
              <PrintPageVisitKeluar />
            </PrivateRouteComp>
          }
        />
        <Route path="/report-absen"
          element={
            <PrivateRouteComp >
              <PrintReportAbsen />
            </PrivateRouteComp>
          }
        />
        <Route path="/print-report-visit"
          element={
            <PrivateRouteComp >
              <PrintReportVisit />
            </PrivateRouteComp>
          }
        />
        <Route path="/report-absen-detail-karyawan"
          element={
            <PrivateRouteComp >
              <PrintReportDetailAbsenKaryawanInti />
            </PrivateRouteComp>
          }
        />
        <Route path="/report-visit-detail-karyawan"
          element={
            <PrivateRouteComp >
              <PrintReportDetailVisitKaryawanInti />
            </PrivateRouteComp>
          }
        />
        {/* <Route path="/reset-password" element={ <ResetPassworPage /> } /> */ }
        <Route path="/reset-password/:userId/:token" element={ <ResetPasswordPage /> } />
        <Route path="*"
          element={
            <NotFoundPages />
          }
        />
      </Routes>
    </BrowserRouter>

  );
}

export default App;

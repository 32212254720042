import React, { useContext, useEffect, useState } from 'react'
import NavbarComponent from '../../component/Navbar'
import { Container, Tab, Tabs } from 'react-bootstrap'
import axios from '../../adapters/API/axios';
import AuthContext from '../../auth/Context/AuthContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import TabVisitInKaryawan from '../../component/TabVisit/TabVisitMasukKaryawan';
import TabVisitOutKaryawan from '../../component/TabVisit/TabVisitOutKaryawan';

function VisitPage ()
{
    const [ visitIn, setVisitIn ] = useState( [] );
    const [ vistiOut, setVisitOut ] = useState( [] );
    const { tokens, userInfo } = useContext( AuthContext );
    const tokenUser = tokens?.token;
    const navigate = useNavigate();
    useEffect( () =>
    {
        const fetchData = async () =>
        {
            if ( userInfo?.id !== undefined && tokenUser !== undefined ) {

                await fetchListVisitIn();
                await fetchListVisitOut();

            }
        };

        fetchData();
    }, [ userInfo?.id, tokenUser ] );


    const fetchListVisitIn = () =>
    {
        axios.get( `/api/visit/visitIn/?user_id=${userInfo?.id}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: `Token ` + tokenUser,
            },
        } )
            .then( res =>
            {

                setVisitIn( res.data );
                // console.log( res.data )

            } ).catch( err =>
            {
                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };

    const fetchListVisitOut = () =>
    {
        axios.get( `/api/visit/visitOut/?user_id=${userInfo?.id}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: `Token ` + tokenUser,
            },
        } )
            .then( res =>
            {


                setVisitOut( res.data );
                // console.log( res.data )
            } ).catch( err =>
            {
                // console.log( err )
            } )
    };



    return (
        <>
            <NavbarComponent />
            <h1 className='display-6 text-center' style={ { fontFamily: 'Poppins-Light' } }>Data Visit</h1>
            <Container className='mt-5'>
                <Tabs
                    defaultActiveKey="masuk"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                >
                    <Tab eventKey="masuk" title="Visit Masuk">
                        <TabVisitInKaryawan
                            visitIn={ visitIn }
                        />
                    </Tab>
                    <Tab eventKey="keluar" title="Visit Keluar">
                        <TabVisitOutKaryawan
                            vistiOut={ vistiOut }
                        />
                    </Tab>
                </Tabs>
            </Container>
        </>
    )
}

export default VisitPage

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../auth/Context/AuthContext';
import axios from '../../adapters/API/axios';
import Swal from 'sweetalert2';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { ActionIcon, Box, Flex } from '@mantine/core';
import { BsPrinter } from 'react-icons/bs';
import ExportExcel from '../../component/ExportExcel';

function DetailAbsenKaryawan ()
{
    const { karyawanid } = useParams();
    const { tokens } = useContext( AuthContext );
    const tokenUser = tokens?.token;
    const [ userDetail, setUserDetail ] = useState();
    const [ absensiMasuk, setAbsensiMasuk ] = useState( [] );
    const [ absensiKeluar, setAbsensiKeluar ] = useState( [] );
    const navigate = useNavigate();
    const [ lateDurations, setLateDurations ] = useState(
        JSON.parse( localStorage.getItem( 'lateDurations' ) ) || []
    );
    const [ earlyOutDurations, setEarlyOutDurations ] = useState(
        JSON.parse( localStorage.getItem( 'earlyOutDurations' ) ) || []
    );
    const [ overtimeDurations, setOvertimeDurations ] = useState(
        JSON.parse( localStorage.getItem( 'overtimeDurations' ) ) || []
    );

    const handleBack = () =>
    {
        navigate( -1 )
    }

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            if ( karyawanid !== undefined && tokenUser !== undefined ) {

                await fetchUserDetail();
            }
        };

        fetchData();
    }, [ karyawanid, tokenUser ] );

    const calculateTotalDuration = ( durations ) =>
    {
        return durations.reduce( ( acc, duration ) =>
        {
            const [ hours, minutes, seconds ] = duration.split( ':' );
            return acc + parseInt( hours ) * 3600 + parseInt( minutes ) * 60 + parseInt( seconds );
        }, 0 );
    };

    const formatDuration = ( totalSeconds ) =>
    {
        const hours = Math.floor( totalSeconds / 3600 );
        const minutes = Math.floor( ( totalSeconds % 3600 ) / 60 );
        const seconds = totalSeconds % 60;

        return `${hours.toString().padStart( 2, '0' )}:${minutes.toString().padStart( 2, '0' )}:${seconds.toString().padStart( 2, '0' )}`;
    };

    const formattedTotalLateDuration = formatDuration( calculateTotalDuration( lateDurations ) );
    const formattedTotalEarlyDuration = formatDuration( earlyOutDurations.length > 0 ? calculateTotalDuration( earlyOutDurations ) : 0 );
    const formattedTotalOverDuration = formatDuration( overtimeDurations.length > 0 ? calculateTotalDuration( overtimeDurations ) : 0 );


    useEffect( () =>
    {
        const fetchData = async () =>
        {
            if ( karyawanid !== undefined && tokenUser !== undefined ) {

                await fetchUserDetail();
                await fetchListAbsensiMasuk();
                await fetchListAbsensiKeluar();

            }
        };

        fetchData();
    }, [ karyawanid, tokenUser ] );


    const fetchUserDetail = () =>
    {
        axios.get( `api/users/${karyawanid}`,
            {
                headers:
                {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: `Token ` + tokenUser,
                },

            } )
            .then( res =>
            {

                setUserDetail( res.data );
                // fetchListGroup( res.data );
                // console.log( res.data )
            } ).catch( err =>
            {
                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };

    const fetchListAbsensiMasuk = () =>
    {
        axios.get( `/api/checkin/?user_id=${karyawanid}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: `Token ` + tokenUser,
            },
        } )
            .then( res =>
            {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-based
                const currentYear = currentDate.getFullYear();

                // Filter data for the current month
                const filteredData = res.data.filter( item =>
                {
                    const checkinDate = new Date( item.checkin_time );
                    return checkinDate.getMonth() + 1 === currentMonth && checkinDate.getFullYear() === currentYear;
                } );

                setAbsensiMasuk( res.data );
                const durationsLate = filteredData.map( ( item ) => item.late_duration || "00:00:00" );
                setLateDurations( durationsLate );

                // console.log( res.data )
            } ).catch( err =>
            {
                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };


    const fetchListAbsensiKeluar = () =>
    {
        axios.get( `/api/checkout/?user_id=${karyawanid}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: `Token ` + tokenUser,
            },
        } )
            .then( res =>
            {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-based
                const currentYear = currentDate.getFullYear();

                // Filter data for the current month
                const filteredData = res.data.filter( item =>
                {
                    const checkoutDate = new Date( item.checkout_time );
                    return checkoutDate.getMonth() + 1 === currentMonth && checkoutDate.getFullYear() === currentYear;
                } );

                setAbsensiKeluar( res.data );
                const durationsEarly = filteredData.map( ( item ) => item.early_duration || "00:00:00" );
                setEarlyOutDurations( durationsEarly );
                const durationsOver = filteredData.map( ( item ) => item.overtime_duration || "00:00:00" );
                setOvertimeDurations( durationsOver );
            } ).catch( err =>
            {
                // console.log( err )
            } )
    };

    const [ dataTable, setDataTable ] = useState( [] );

    useEffect( () =>
    {
        const dataTableFilter = absensiMasuk.map( ( { checkin_place, checkin_location, ...rest } ) => rest );

        setDataTable(
            dataTableFilter.map( ( data ) =>
            {
                const tanggal = absensiKeluar.find( ( item ) => item.checkout_time.split( 'T' )[ 0 ] === data.checkin_time.split( 'T' )[ 0 ] );
                const checkOutTime = tanggal ? tanggal.checkout_time : '';
                const earlyDuration = tanggal ? tanggal.early_duration : '';
                const overtimeDuration = tanggal ? tanggal.overtime_duration : '';

                return {
                    ...data,
                    checkout_time: checkOutTime,
                    early_duration: earlyDuration,
                    overtime_duration: overtimeDuration,
                };
            } )
        );
    }, [ absensiMasuk, absensiKeluar ] );

    const columns = useMemo(
        () => [
            {
                header: 'Tanggal Masuk',
                accessorFn: ( row ) => row.checkin_time ? new Date( row.checkin_time ) : null,
                id: 'tanggal_absen_masuk',
                filterVariant: 'date-range',
                Cell: ( { cell } ) =>
                {
                    const date = cell.getValue();
                    if ( !date ) {
                        return '';
                    }
                    const day = String( date.getDate() ).padStart( 2, '0' );
                    const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Tanggal Pulang',
                accessorFn: ( row ) => row.checkout_time ? new Date( row.checkout_time ) : null,
                id: 'tanggal_absen_pulang',
                filterVariant: 'date-range',
                Cell: ( { cell } ) =>
                {
                    const date = cell.getValue();
                    if ( !date ) {
                        return '';
                    }
                    const day = String( date.getDate() ).padStart( 2, '0' );
                    const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Jam Masuk',
                accessorFn: ( row ) => (
                    row.checkin_time ? row.checkin_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : ''
                ),
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 50,
            },
            {
                header: 'Jam Pulang',
                accessorFn: ( row ) => (
                    row.checkout_time ? row.checkout_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : ''
                ),
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 50,
            },
            {
                header: 'Telat',
                accessorKey: 'late_duration',
                mantineTableHeadCellProps: {
                    align: 'right',
                },
                mantineTableBodyCellProps: {
                    align: 'right',
                },

                size: 50,
            },
            {
                header: 'Pulang Awal',
                accessorKey: 'early_duration',
                mantineTableHeadCellProps: {
                    align: 'right',
                },
                mantineTableBodyCellProps: {
                    align: 'right',
                },
                size: 50,
            },
            {
                header: 'Lembur',
                accessorKey: 'overtime_duration',
                mantineTableHeadCellProps: {
                    align: 'right',
                },
                mantineTableBodyCellProps: {
                    align: 'right',
                },
                size: 50,
            },

        ],
        [],
    );

    const handlePrint = () =>
    {
        navigate( '/report-absen-detail-karyawan', { state: { dataTable: dataTable, user_name: userDetail?.first_name + " " + userDetail?.last_name } } );
    };

    const handleExportRowsExcelData = () =>
    {
        const excelData = dataTable.map( ( row ) => ( {
            'Tanggal Masuk': row?.checkin_time ? row.checkin_time.split( 'T' )[ 0 ] : '',
            'Tanggal Pulang': row?.checkout_time ? row.checkout_time.split( 'T' )[ 0 ] : '',
            'Jam Masuk': row?.checkin_time ? row.checkin_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : '',
            'Jam Pulang': row?.checkout_time ? row.checkout_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : '',
            'Keterlambatan': row.late_duration,
            'Pulang Awal': row.early_duration,
            'Lembur': row.overtime_duration,
        } ) );
        return excelData;
    };;

    const table = useMantineReactTable( {
        columns,
        enableDensityToggle: false,
        initialState: {
            density: 'xs',
        },
        data: dataTable,
        enableRowNumbers: true,
        rowNumberMode: 'static',
        enableGlobalFilter: false,
        enableColumnResizing: false,
        isMultiSortEvent: () => true,
        mantineTableProps: {
            striped: true,

        }, renderTopToolbarCustomActions: ( { table } ) => (
            <Box
                sx={ {
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                } }
            >
            </Box>
        ),
        //customize built-in buttons in the top-right of top toolbar
        renderToolbarInternalActions: ( { table } ) => (
            <Flex gap="xs" align="center">
                {/* add custom button to print table  */ }
                <ActionIcon
                    onClick={ handlePrint }
                >
                    <BsPrinter
                        style={ { backgroundColor: 'transparent', color: '#222' } }
                        size={ 40 }
                    />
                </ActionIcon>
                <ActionIcon>
                    <ExportExcel
                        excelData={ handleExportRowsExcelData( table.getRowModel().rows ) }
                        fileName={ `Report Absensi Detail ${userDetail?.first_name} ${userDetail?.last_name}` }
                    />
                </ActionIcon>
            </Flex>
        ),
    } );


    // console.log( dataTable );

    return (
        <>
            <Container className='mt-4'>
                <Row>
                    <Col xs={ 6 } md={ 11 } className='my-auto'>
                        <h1 className='display-6 text-start' style={ { fontFamily: 'Poppins-Light' } } >Detail Absensi</h1>

                    </Col>
                    <Col xs={ 6 } md={ 1 } className='text-end'>
                        <Button
                            onClick={ handleBack }
                            variant='btn'
                            style={ { minHeight: '50px', backgroundColor: '#696969', color: 'white', fontFamily: 'Poppins-Regular' } }
                        >
                            Kembali
                        </Button>
                    </Col>
                </Row>
            </Container>
            <h3 className='text-center mt-3' style={ { fontFamily: 'Poppins-Regular' } }>{ userDetail?.first_name } { userDetail?.last_name }</h3>
            <Container className='mt-5'>
                <MantineReactTable
                    table={ table }
                />
                <div className='my-3' style={ { fontFamily: 'Poppins-Regular' } }>
                    <h5>Total Keterlambatan Bulan Ini: { formattedTotalLateDuration }</h5>
                    <h5>Total Pulang Awal Bulan Ini: { formattedTotalEarlyDuration }</h5>
                    <h5>Total Lembur Bulan Ini: { formattedTotalOverDuration }</h5>
                </div>
            </Container>
        </>
    )
}

export default DetailAbsenKaryawan

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NavbarComponent from '../../component/Navbar';
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import AuthContext from '../../auth/Context/AuthContext';
import axios from '../../adapters/API/axios';
import LocationMarker from '../../component/MapsReader';
import instance from '../../adapters/API/axios';
import Swal from 'sweetalert2';

function DetailVisit ()
{
    const { tokens } = useContext( AuthContext );
    const tokenUser = tokens?.token;
    const { absenid } = useParams();
    const [ detailVisitMasuk, setDetailVisitMasuk ] = useState();
    const [ detailLokasiVisitMasuk, setDetailLokasiVisitMasuk ] = useState();
    const [ detailVisitKeluar, setDetailVisitKeluar ] = useState();
    const [ detailLokasiVisitKeluar, setDetailLokasiVisitKeluar ] = useState();
    const [ done, setDone ] = useState( undefined );
    const navigate = useNavigate();
    const handleBack = () =>
    {
        navigate( -1 )
    }

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            if ( absenid !== undefined && tokenUser !== undefined ) {

                await fetchListAdetailVisitMasuk();
                await fetchListAdetailVisitKeluar();
            }
        };

        fetchData();
    }, [ absenid, tokenUser ] );

    const fetchListAdetailVisitMasuk = () =>
    {
        axios.get( `/api/visit/visitIn/${absenid}`,
            {
                headers:
                {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: `Token ` + tokenUser,
                },

            } )
            .then( res =>
            {
                setDetailVisitMasuk( res.data )
                const [ longitude, latitude ] = res.data.visitIn_location
                    .match( /POINT \(([-0-9.]+) ([-0-9.]+)\)/ )
                    .slice( 1 )
                    .map( Number );
                setTimeout( () =>
                {
                    setDone( true );
                }, 2000 );
                setDetailLokasiVisitMasuk( { longitude, latitude } );

                // console.log( res.data )
            } ).catch( err =>
            {
                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };

    const fetchListAdetailVisitKeluar = () =>
    {
        axios.get( `/api/visit/visitOut/${absenid}`,
            {
                headers:
                {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: `Token ` + tokenUser,
                },

            } )
            .then( res =>
            {

                setDetailVisitKeluar( res.data )
                const [ longitude, latitude ] = res.data.visitOut_location
                    .match( /POINT \(([-0-9.]+) ([-0-9.]+)\)/ )
                    .slice( 1 )
                    .map( Number );
                setTimeout( () =>
                {
                    setDone( true );
                }, 2000 );
                setDetailLokasiVisitKeluar( { longitude, latitude } );
                // console.log( res.data )
            } ).catch( err =>
            {

                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };


    return (
        <>
            <NavbarComponent />
            <Container>
                <Row>
                    <Col xs={ 6 } md={ 11 }>
                        <h1 className='display-6 text-start my-2' style={ { fontFamily: 'Poppins-Light' } }>Detail Absensi</h1>
                    </Col>
                    <Col xs={ 6 } md={ 1 } className='my-auto text-end'>
                        <Button
                            onClick={ handleBack }
                            variant='btn'
                            style={ { minHeight: '50px', backgroundColor: '#696969', color: 'white', fontFamily: 'Poppins-Regular' } }
                        >
                            Kembali
                        </Button>
                    </Col>
                </Row>
            </Container>


            <Container className='mt-5'>
                <h5 className='text-center' style={ { fontFamily: 'Poppins-Regular' } }>Visit Masuk</h5>
                { !done ? (
                    <div className='text-center'>
                        <Spinner animation="border" size='lg' className='my-4' style={ { color: '#000A2E' } } />
                    </div>

                ) : (
                    <>
                        { !detailVisitMasuk ? (
                            <Alert variant="danger" className='text-center mt-5'>
                                <Alert.Heading style={ { fontFamily: 'Poppins-SemiBold' } }>
                                    Maaf, user ini belum melakukan visit masuk
                                </Alert.Heading>
                            </Alert>
                        ) : (
                            <Row className='mt-5' style={ { minHeight: '300px' } }>
                                <Col md={ 6 } className='my-auto' style={ { fontFamily: 'Poppins-Regular' } }>
                                    <p>Lokasi: { detailVisitMasuk?.visitIn_place }</p>
                                    <p>Waktu: { detailVisitMasuk?.visitIn_time.split( 'T' )[ 0 ] } { detailVisitMasuk?.visitIn_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] }</p>
                                </Col>
                                <Col md={ 6 } className='text-center'>
                                    { !detailLokasiVisitMasuk ? (
                                        <Alert variant="danger" style={ { minHeight: '200px' } }>
                                            <Alert.Heading style={ { fontFamily: 'Poppins-SemiBold' } }>
                                                Lokasi user tidak ditemukan!
                                            </Alert.Heading>
                                            <p className='mt-5' style={ { fontFamily: 'Poppins-Regular' } }>
                                                Pastikan user sebelum visit masuk menghidupkan GPS, dan menyetujui permintaan
                                                dari Browser untuk menggunakan Lokasi Terkini.
                                            </p>
                                        </Alert>
                                    ) : (
                                        <LocationMarker latitude={ detailLokasiVisitMasuk.latitude } longitude={ detailLokasiVisitMasuk.longitude } />
                                    ) }
                                </Col>
                            </Row>
                        ) }
                    </>
                ) }
            </Container>
            <Container className='my-5'>
                <h5 className='text-center' style={ { fontFamily: 'Poppins-Regular' } }>Visit Keluar</h5>
                { !done ? (
                    <div className='text-center'>
                        <Spinner animation="border" size='lg' className='my-4' style={ { color: '#000A2E' } } />
                    </div>

                ) : (
                    <>
                        { !detailVisitKeluar ? (
                            <Alert variant="danger" className='text-center mt-5'>
                                <Alert.Heading style={ { fontFamily: 'Poppins-SemiBold' } }>
                                    Maaf, user ini belum melakukan visit keluar
                                </Alert.Heading>
                            </Alert>
                        ) : (
                            <Row className='mt-5' style={ { minHeight: '300px' } }>
                                <Col md={ 6 } className='my-auto' style={ { fontFamily: 'Poppins-Regular' } }>
                                    <p>Lokasi: { detailVisitKeluar?.visitOut_place }</p>
                                    <p>Waktu: { detailVisitKeluar?.visitOut_time.split( 'T' )[ 0 ] } { detailVisitKeluar?.visitOut_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] }</p>
                                </Col>
                                <Col md={ 6 } className='text-center'>
                                    { !detailLokasiVisitKeluar ? (
                                        <Alert variant="danger" style={ { minHeight: '200px' } }>
                                            <Alert.Heading style={ { fontFamily: 'Poppins-SemiBold' } }>
                                                Lokasi user tidak ditemukan!
                                            </Alert.Heading>
                                            <p className='mt-5' style={ { fontFamily: 'Poppins-Regular' } }>
                                                Pastikan user sebelum visit keluar menghidupkan GPS, dan menyetujui permintaan
                                                dari Browser untuk menggunakan Lokasi Terkini.
                                            </p>
                                        </Alert>
                                    ) : (
                                        <LocationMarker latitude={ detailLokasiVisitKeluar.latitude } longitude={ detailLokasiVisitKeluar.longitude } />
                                    ) }
                                </Col>
                            </Row>
                        ) }
                    </>
                ) }
            </Container>
        </>
    )
}

export default DetailVisit

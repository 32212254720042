import { ActionIcon, Box, Flex } from '@mantine/core';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useMemo } from 'react'
import { BsPrinter } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import ExportExcel from '../../ExportExcel';
import { GoInfo } from 'react-icons/go';

function TabVisitInKaryawanPrint ( {
    visitIn,
    karyawanid,
    userDetail,
    handleVisitMasukDetail
} )
{

    const navigate = useNavigate();

    const printAbsensi = () =>
    {
        navigate( "/visit-masuk/" + karyawanid );
    }

    const columns = useMemo(
        () => [
            {
                header: 'Detail',
                accessorFn: row => (
                    <div >
                        <GoInfo
                            size={ 20 }
                            onClick={ () => handleVisitMasukDetail( row ) }
                            style={ { cursor: 'pointer' } }
                        />

                    </div>
                ),
                mantineTableHeadCellProps: {
                    align: 'left',
                },
                mantineTableBodyCellProps: {
                    align: 'left',
                },
                size: 100,
            },
            {
                header: 'Tanggal',
                id: 'tanggal',
                accessorFn: ( row ) => new Date( row.visitIn_time ),
                filterVariant: 'date-range',
                Cell: ( { cell } ) =>
                {
                    const date = cell.getValue();
                    const day = String( date.getDate() ).padStart( 2, '0' );
                    const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Jam',
                accessorFn: ( row ) => (
                    row.visitIn_time.split( 'T' )[ 1 ].split( '.' )[ 0 ]
                ),
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Lokasi Visit',
                accessorKey: 'visitIn_place',
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,

            },

        ],
        [],
    );

    const handleExportRowsExcelData = () =>
    {
        const excelData = visitIn.map( ( row ) => ( {
            'Visit Masuk': row.visitIn_time,
            'Lokasi Visit Masuk': row.visitIn_place,
        } ) );
        return excelData;
    };


    const table = useMantineReactTable( {
        columns,
        enableDensityToggle: false,
        initialState: {
            density: 'xs',
            sorting: [
                {
                    id: 'tanggal', //sort by age by default on page load
                    desc: true,
                },
            ],
        },
        data: visitIn,
        enableRowNumbers: true,
        rowNumberMode: 'static',
        enableGlobalFilter: false,
        enableColumnResizing: false,
        isMultiSortEvent: () => true,
        mantineTableProps: {
            striped: true,

        },
        renderTopToolbarCustomActions: ( { table } ) => (
            <Box
                sx={ {
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                } }
            >
            </Box>
        ),
        //customize built-in buttons in the top-right of top toolbar
        renderToolbarInternalActions: ( { table } ) => (
            <Flex gap="xs" align="center">
                {/* add custom button to print table  */ }
                <ActionIcon
                    onClick={ printAbsensi }
                >
                    <BsPrinter
                        style={ { backgroundColor: 'transparent', color: '#222' } }
                        size={ 40 }
                    />
                </ActionIcon>
                <ActionIcon>
                    <ExportExcel
                        excelData={ handleExportRowsExcelData( table.getRowModel().rows ) }
                        fileName={ `Data Visit Masuk ${userDetail?.first_name} ${userDetail?.last_name}` }
                    />
                </ActionIcon>
            </Flex>
        ),
    } );

    return (
        <div>
            <MantineReactTable
                table={ table }
            />
        </div>
    )
}

export default TabVisitInKaryawanPrint

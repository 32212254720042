import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../auth/Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from '../../adapters/API/axios';
import NavbarComponent from '../../component/Navbar';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { GoInfo } from 'react-icons/go';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { ActionIcon, Box, Flex } from '@mantine/core';
import { BsPrinter } from 'react-icons/bs';
import ExportExcel from '../../component/ExportExcel';

function ReportVisit ()
{
    const { tokens } = useContext( AuthContext );
    const [ listGroup, setListGroup ] = useState( [] );
    const [ startDate, setStartDate ] = useState( '' );
    const [ endDate, setEndDate ] = useState( '' );
    const [ dataTableCheckins, setDataTableCheckins ] = useState( [] );
    const [ dataTableCheckouts, setDataTableCheckouts ] = useState( [] );
    const tokenUser = tokens?.token;
    const navigate = useNavigate();

    useEffect( () =>
    {
        if ( tokenUser !== undefined ) fetchListGroup()
    }, [ tokenUser ] );

    const fetchListGroup = () =>
    {
        axios.get( `/api/groups/`,
            {
                headers:
                {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: `Token ` + tokenUser,
                },

            } )
            .then( res =>
            {
                let filteredArray = res.data.filter( item => item.name !== 'superuser' );
                setListGroup( filteredArray );

            } ).catch( err =>
            {

                console.log( err )
            } )
    };

    const [ selectedGroup, setSelectedGroup ] = useState( null );

    const divisiOptions = listGroup.map( group => ( {
        value: group.id,
        label: group.name,
    } ) );

    const handleSelectGroup = selectedOption =>
    {
        setSelectedGroup( selectedOption );
    };

    const [ userDataCheckin, setUserDataCheckin ] = useState( [] );
    const [ userDataCheckout, setUserDataCheckout ] = useState( [] );

    useEffect( () =>
    {
        const aggregatedData = {};
        dataTableCheckins.forEach( ( item ) =>
        {
            const { user_id, user } = item;


            if ( aggregatedData[ user ] ) {
                aggregatedData[ user ].jumlah_visit_masuk++;

            } else {
                aggregatedData[ user ] = {
                    user_id,
                    jumlah_visit_masuk: 1,
                };
            }
        } );

        const formattedData = Object.keys( aggregatedData ).map( ( user ) =>
        {

            return {
                user_id: aggregatedData[ user ].user_id,
                user,
                jumlah_visit_masuk: aggregatedData[ user ].jumlah_visit_masuk,
            };
        } );

        setUserDataCheckin( formattedData );
    }, [ dataTableCheckins ] );

    useEffect( () =>
    {
        const aggregatedData = {};
        dataTableCheckouts.forEach( ( item ) =>
        {
            const { user_id, user } = item;


            if ( aggregatedData[ user ] ) {
                aggregatedData[ user ].jumlah_visit_keluar++;

            } else {
                aggregatedData[ user ] = {
                    user_id,
                    jumlah_visit_keluar: 1,
                };
            }
        } );

        const formattedData = Object.keys( aggregatedData ).map( ( user ) =>
        {

            return {
                user_id: aggregatedData[ user ].user_id,
                user,
                jumlah_visit_keluar: aggregatedData[ user ].jumlah_visit_keluar,
            };
        } );

        setUserDataCheckout( formattedData );
    }, [ dataTableCheckouts ] );

    // useEffect( () =>
    // {
    //     const aggregatedData = {};
    //     dataTableCheckouts.forEach( ( item ) =>
    //     {
    //         const { user_id, user, early_duration, overtime_duration } = item;

    //         const earlyDurationInHours = early_duration / 3600; // Assuming late_duration is in seconds
    //         const overtimeDurationInHours = overtime_duration / 3600; // Assuming late_duration is in seconds
    //         if ( aggregatedData[ user ] ) {
    //             aggregatedData[ user ].jumlah_absen_masuk++;
    //             aggregatedData[ user ].total_early_duration += earlyDurationInHours;
    //             aggregatedData[ user ].total_overtime_duration += overtimeDurationInHours;
    //         } else {
    //             aggregatedData[ user ] = {
    //                 user_id,
    //                 jumlah_absen_masuk: 1,
    //                 total_early_duration: earlyDurationInHours,
    //                 total_overtime_duration: overtimeDurationInHours,
    //             };
    //         }
    //     } );

    //     const formattedData = Object.keys( aggregatedData ).map( ( user ) =>
    //     {
    //         const earlyDurationInHours = Math.floor( aggregatedData[ user ].total_early_duration ); // Extract hours (integer part)
    //         const earlyDurationMinutes = Math.round( ( aggregatedData[ user ].total_early_duration - earlyDurationInHours ) * 60 ); // Convert remaining decimal to minutes
    //         const formattedEarlyDuration = `${earlyDurationInHours}:${earlyDurationMinutes < 10 ? '0' : ''}${earlyDurationMinutes}:00`; // Format minutes as two digits

    //         const overtimeDurationInHours = Math.floor( aggregatedData[ user ].total_overtime_duration ); // Extract hours (integer part)
    //         const overtimeDurationMinutes = Math.round( ( aggregatedData[ user ].total_overtime_duration - overtimeDurationInHours ) * 60 ); // Convert remaining decimal to minutes
    //         const formattedOvertimeDuration = `${overtimeDurationInHours}:${overtimeDurationMinutes < 10 ? '0' : ''}${overtimeDurationMinutes}:00`; // Format minutes as two digits

    //         return {
    //             user_id: aggregatedData[ user ].user_id,
    //             user,
    //             jumlah_absen_pulang: aggregatedData[ user ].jumlah_absen_masuk,
    //             early_duration: formattedEarlyDuration,
    //             overtime_duration: formattedOvertimeDuration,
    //         };
    //     } );

    //     setUserDataCheckout( formattedData );
    // }, [ dataTableCheckouts ] );

    const [ dataTable, setDataTable ] = useState( [] );

    useEffect( () =>
    {
        const dataTableFilter = userDataCheckin.map( ( { ...rest } ) => rest );

        setDataTable(
            dataTableFilter.map( ( data ) =>
            {
                const user = userDataCheckout.find( ( user ) => user.user_id === data.user_id );
                const jumlahVisitKeluar = user ? user.jumlah_visit_keluar : '';

                return {
                    ...data,
                    jumlah_visit_keluar: jumlahVisitKeluar,
                };
            } )
        );
    }, [ userDataCheckin, userDataCheckout ] );

    // console.log( dataTable )

    const handleSubmitReport = async ( event ) =>
    {
        event.preventDefault()

        const group_name = selectedGroup?.label;
        const start_date = startDate;
        const end_date = endDate;
        try {
            const response = await axios.get( `/api/report/visit/?group=${group_name}&start_date=${start_date}&end_date=${end_date}`,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        withCredentials: true,
                        Authorization: `Token ` + tokenUser,
                    },
                }

            );

            // console.log( response )
            setDataTableCheckins( response.data.visitins )
            setDataTableCheckouts( response.data.visitouts )
        } catch ( err ) {
            console.log( err )
        }
    };

    // console.log( userDataCheckin );
    // console.log( userDataCheckout );
    // console.log( dataTableCheckouts );

    const group_name = selectedGroup?.label;
    const start_date = startDate;
    const end_date = endDate;

    const getTodayDate = () =>
    {
        const today = new Date();
        const year = today.getFullYear();
        const month = String( today.getMonth() + 1 ).padStart( 2, '0' );
        const day = String( today.getDate() ).padStart( 2, '0' );
        return `${year}-${month}-${day}`;
    };

    const handlePrint = () =>
    {
        navigate( '/print-report-visit', { state: { dataTable: dataTable, group_name: group_name, start_date: start_date, end_date: end_date } } );
    };


    const handleDetail = ( row ) =>
    {
        navigate( '/report-visit-detail-karyawan/' + row.user_id )
    }

    const getColumns = () => [
        {
            header: 'Detail',
            accessorFn: row => (
                <div >
                    <GoInfo
                        size={ 20 }
                        onClick={ () => handleDetail( row ) }
                        style={ { cursor: 'pointer' } }
                    />

                </div>
            ),
            size: 10,
            mantineTableHeadCellProps: {
                align: 'left',
            },
            mantineTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Username',
            accessorKey: 'user',
            mantineTableHeadCellProps: {
                align: 'center',
            },
            mantineTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            header: 'Jumlah Visit Masuk',
            accessorKey: 'jumlah_visit_masuk',
            mantineTableHeadCellProps: {
                align: 'center',
            },
            mantineTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            header: 'Jumlah Visit Keluar',
            accessorKey: 'jumlah_visit_keluar',
            mantineTableHeadCellProps: {
                align: 'center',
            },
            mantineTableBodyCellProps: {
                align: 'center',
            },
        },
    ];

    const columns = getColumns();

    const handleExportRowsExcelData = () =>
    {
        const excelData = dataTable.map( ( row ) => ( {
            'Username': row.user,
            'Jumlah Visit Masuk': row.jumlah_visit_masuk,
            'Jumlah Visit Keluar': row.jumlah_visit_keluar,
        } ) );
        return excelData;
    };

    const table = useMantineReactTable( {
        columns,
        enableDensityToggle: false,
        initialState: {
            density: 'xs',
            sorting: [
                {
                    id: 'user',
                    asc: true,
                },
            ],
        },
        data: dataTable,
        enableRowNumbers: true,
        rowNumberMode: 'static',
        enableGlobalFilter: false,
        enableColumnResizing: false,
        isMultiSortEvent: () => true,
        mantineTableProps: {
            striped: true,

        },
        renderTopToolbarCustomActions: ( { table } ) => (
            <Box
                sx={ {
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                } }
            >
            </Box>
        ),

        renderToolbarInternalActions: ( { table } ) => (
            <Flex gap="xs" align="center">

                <ActionIcon
                    onClick={ handlePrint }
                >
                    <BsPrinter
                        style={ { backgroundColor: 'transparent', color: '#222' } }
                        size={ 40 }
                    />
                </ActionIcon>
                <ActionIcon>
                    <ExportExcel
                        excelData={ handleExportRowsExcelData( table.getRowModel().rows ) }
                        fileName={ `Report Visit ${group_name} Dari Tanggal ${start_date} Sampai Tanggal ${end_date}` }
                    />
                </ActionIcon>
            </Flex>
        ),
    } );

    return (
        <>
            <NavbarComponent />
            <Container>
                <Row>
                    <h1 className='display-6 text-center' style={ { fontFamily: 'Poppins-Light' } }>Reporting Visit</h1>
                </Row>
            </Container>
            <Container className='mt-5'>
                <div>
                    <Form onSubmit={ handleSubmitReport }>
                        <Row>
                            <Col lg={ 5 }>
                                <Form.Group className='mb-3'>
                                    <Form.Label style={ formStyles.label } htmlFor='group'>Pilih Divisi</Form.Label>
                                    <Select
                                        id='group'
                                        options={ divisiOptions }
                                        required
                                        value={ selectedGroup }
                                        onChange={ handleSelectGroup }
                                        styles={ selectStyles }
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={ 3 }>
                                <Form.Group className='mb-3'>
                                    <Form.Label style={ formStyles.label } htmlFor='startDate'>Dari Tanggal</Form.Label>
                                    <Form.Control
                                        id='startDate'
                                        type='date'
                                        max={ getTodayDate() }
                                        required
                                        onChange={ ( e ) => setStartDate( e.target.value ) }
                                        value={ startDate }
                                        style={ formStyles.input }
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={ 3 }>
                                <Form.Group className='mb-3'>
                                    <Form.Label style={ formStyles.label } htmlFor='endDate'>Sampai Tanggal</Form.Label>
                                    <Form.Control
                                        id='endDate'
                                        type='date'
                                        max={ getTodayDate() }
                                        required
                                        onChange={ ( e ) => setEndDate( e.target.value ) }
                                        value={ endDate }
                                        style={ formStyles.input }
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={ 1 } className='my-auto'>
                                <Button
                                    type="submit"
                                    className='mt-3'
                                    id='actionButton'
                                    variant='btn'
                                    style={ { minHeight: '49px' } }
                                >
                                    Pilih
                                </Button>
                            </Col>
                        </Row>
                        {/* <div className="d-grid gap-2 mb-4">
                            
                        </div> */}
                    </Form>
                </div>

                <div className='mt-4' >
                    <MantineReactTable
                        table={ table }
                    />
                </div>
            </Container>
        </>
    )
}

export default ReportVisit



const formStyles = {
    label: {
        fontFamily: 'Poppins-Medium',
        color: '#222',
    },
    input: {
        color: '#222',
        fontFamily: 'Poppins-Regular',
        minHeight: '50px',
        borderColor: '#ced4da', // Initial border color
    },
};


// Custom styles for react-select
const selectStyles = {
    control: ( provided, state ) => ( {
        ...provided,
        minHeight: '50px', // Adjust the height as needed
        border: state.isFocused ? '1px solid #80bdff' : '1px solid #ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.3rem rgba(0, 123, 255, 0.25)' : null,
        '&:hover': {
            borderColor: '#80bdff',
        },
        fontFamily: 'Poppins-Regular',
        maxHeight: '60px'
    } ),
    option: ( provided, state ) => ( {
        ...provided,
        color: state.isSelected ? '#fff' : '#333',
        background: state.isSelected ? '#007bff' : '#fff',
        fontFamily: 'Poppins-Regular',
        maxHeight: '60px'
    } ),
};

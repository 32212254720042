import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../auth/Context/AuthContext';
import axios from '../../adapters/API/axios';
import Swal from 'sweetalert2';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { ActionIcon, Box, Flex } from '@mantine/core';
import { BsPrinter } from 'react-icons/bs';
import ExportExcel from '../../component/ExportExcel';
import { Button, Col, Container, Row } from 'react-bootstrap';

function DetailAbsenVisitKaryawan ()
{
    const { karyawanid } = useParams();
    const { tokens } = useContext( AuthContext );
    const tokenUser = tokens?.token;
    const [ userDetail, setUserDetail ] = useState();
    const [ visitMasuk, setVisitMasuk ] = useState( [] );
    const [ visitKeluar, setVisitKeluar ] = useState( [] );

    const navigate = useNavigate();

    const handleBack = () =>
    {
        navigate( -1 )
    }

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            if ( karyawanid !== undefined && tokenUser !== undefined ) {

                await fetchUserDetail();
                await fetchListVisitMasuk();
                await fetchListVisitKeluar();

            }
        };

        fetchData();
    }, [ karyawanid, tokenUser ] );

    const fetchUserDetail = () =>
    {
        axios.get( `api/users/${karyawanid}`,
            {
                headers:
                {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    Authorization: `Token ` + tokenUser,
                },

            } )
            .then( res =>
            {

                setUserDetail( res.data );
                // fetchListGroup( res.data );
                // console.log( res.data )
            } ).catch( err =>
            {
                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };

    const fetchListVisitMasuk = () =>
    {
        axios.get( `/api/visit/visitIn/?user_id=${karyawanid}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: `Token ` + tokenUser,
            },
        } )
            .then( res =>
            {

                setVisitMasuk( res.data );
                // console.log( res.data )

            } ).catch( err =>
            {
                if ( err.response?.status === 401 ) {
                    Swal.fire( {
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Sesi Anda telah berakhir. Silahkan Login kembali.',
                        confirmButtonText: 'Login',
                    } ).then( ( result ) =>
                    {
                        if ( result.isConfirmed ) {
                            navigate( '/' );
                        }
                    } );

                } else ( console.log( err ) )
            } )
    };

    const fetchListVisitKeluar = () =>
    {
        axios.get( `/api/visit/visitOut/?user_id=${karyawanid}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                withCredentials: true,
                Authorization: `Token ` + tokenUser,
            },
        } )
            .then( res =>
            {


                setVisitKeluar( res.data );
                // console.log( res.data )
            } ).catch( err =>
            {
                // console.log( err )
            } )
    };

    const [ dataTable, setDataTable ] = useState( [] );

    useEffect( () =>
    {
        const dataTableFilter = visitMasuk.map( ( { visitIn_location, ...rest } ) => rest );

        setDataTable(
            dataTableFilter.map( ( data ) =>
            {
                const mainItem = visitKeluar.find( ( item ) => item.visitOut_time.split( 'T' )[ 0 ] === data.visitIn_time.split( 'T' )[ 0 ] );
                const visitOutTime = mainItem ? mainItem.visitOut_time : '';
                const visitOutPlace = mainItem ? mainItem.visitOut_place : '';

                return {
                    ...data,
                    visit_out_time: visitOutTime,
                    visit_out_place: visitOutPlace,
                };
            } )
        );
    }, [ visitMasuk, visitKeluar ] );

    const columns = useMemo(
        () => [
            {
                header: 'Tanggal Visit Masuk',
                accessorFn: ( row ) => row.visitIn_time ? new Date( row.visitIn_time ) : null,
                id: 'tanggal_absen_masuk',
                filterVariant: 'date-range',
                Cell: ( { cell } ) =>
                {
                    const date = cell.getValue();
                    if ( !date ) {
                        return '';
                    }
                    const day = String( date.getDate() ).padStart( 2, '0' );
                    const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Tanggal Visit Keluar',
                accessorFn: ( row ) => row.visit_out_time ? new Date( row.visit_out_time ) : null,
                id: 'tanggal_absen_pulang',
                filterVariant: 'date-range',
                Cell: ( { cell } ) =>
                {
                    const date = cell.getValue();
                    if ( !date ) {
                        return '';
                    }
                    const day = String( date.getDate() ).padStart( 2, '0' );
                    const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                },
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 150,
            },
            {
                header: 'Jam Visit Masuk',
                accessorFn: ( row ) => (
                    row.visitIn_time ? row.visitIn_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : ''
                ),
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 50,
            },
            {
                header: 'Jam Visit Keluar',
                accessorFn: ( row ) => (
                    row.visit_out_time ? row.visit_out_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : ''
                ),
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                },
                size: 50,
            },
            {
                header: 'Lokasi Visit Masuk',
                accessorKey: 'visitIn_place',
                mantineTableHeadCellProps: {
                    align: 'right',
                },
                mantineTableBodyCellProps: {
                    align: 'right',
                },

                size: 50,
            },
            {
                header: 'Lokasi Visit Keluar',
                accessorKey: 'visit_out_place',
                mantineTableHeadCellProps: {
                    align: 'right',
                },
                mantineTableBodyCellProps: {
                    align: 'right',
                },

                size: 50,
            },
        ],
        [],
    );

    const handlePrint = () =>
    {
        navigate( '/report-visit-detail-karyawan', { state: { dataTable: dataTable, user_name: userDetail?.first_name + " " + userDetail?.last_name } } );
    };

    const handleExportRowsExcelData = () =>
    {
        const excelData = dataTable.map( ( row ) => ( {
            'Tanggal Visit Masuk': row?.visitIn_time ? row.visitIn_time.split( 'T' )[ 0 ] : '',
            'Tanggal Visit Keluar': row?.visit_out_time ? row.visit_out_time.split( 'T' )[ 0 ] : '',
            'Jam Visit Masuk': row?.visitIn_time ? row.visitIn_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : '',
            'Jam Visit Keluar': row?.visit_out_time ? row.visit_out_time.split( 'T' )[ 1 ].split( '.' )[ 0 ] : '',
            'Lokasi Visit Masuk': row.visitIn_place,
            'Lokasi Visit Keluar': row.visit_out_place,
        } ) );
        return excelData;
    };;

    const table = useMantineReactTable( {
        columns,
        enableDensityToggle: false,
        initialState: {
            density: 'xs',
        },
        data: dataTable,
        enableRowNumbers: true,
        rowNumberMode: 'static',
        enableGlobalFilter: false,
        enableColumnResizing: false,
        isMultiSortEvent: () => true,
        mantineTableProps: {
            striped: true,

        }, renderTopToolbarCustomActions: ( { table } ) => (
            <Box
                sx={ {
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                } }
            >
            </Box>
        ),
        //customize built-in buttons in the top-right of top toolbar
        renderToolbarInternalActions: ( { table } ) => (
            <Flex gap="xs" align="center">
                {/* add custom button to print table  */ }
                <ActionIcon
                    onClick={ handlePrint }
                >
                    <BsPrinter
                        style={ { backgroundColor: 'transparent', color: '#222' } }
                        size={ 40 }
                    />
                </ActionIcon>
                <ActionIcon>
                    <ExportExcel
                        excelData={ handleExportRowsExcelData( table.getRowModel().rows ) }
                        fileName={ `Report Visit Detail ${userDetail?.first_name} ${userDetail?.last_name}` }
                    />
                </ActionIcon>
            </Flex>
        ),
    } );

    return (
        <>
            <Container className='mt-4'>
                <Row>
                    <Col xs={ 6 } md={ 11 } className='my-auto'>
                        <h1 className='display-6 text-start' style={ { fontFamily: 'Poppins-Light' } } >Detail Absensi</h1>

                    </Col>
                    <Col xs={ 6 } md={ 1 } className='text-end'>
                        <Button
                            onClick={ handleBack }
                            variant='btn'
                            style={ { minHeight: '50px', backgroundColor: '#696969', color: 'white', fontFamily: 'Poppins-Regular' } }
                        >
                            Kembali
                        </Button>
                    </Col>
                </Row>
            </Container>
            <h3 className='text-center mt-3' style={ { fontFamily: 'Poppins-Regular' } }>{ userDetail?.first_name } { userDetail?.last_name }</h3>
            <Container className='mt-5'>
                <MantineReactTable
                    table={ table }
                />
            </Container>
        </>
    )
}

export default DetailAbsenVisitKaryawan

import React from 'react'
import { Container } from 'react-bootstrap'

function NotFoundPages ()
{
  return (
    <Container fluid id='notFoundPage'>
    </Container>
  )
}

export default NotFoundPages
